<template>
  <div class="knowledge">
    <el-image
      class="banner"
      :src="require('@as/serve/knowledge/banner.png')"
    ></el-image>
    <div class="tag_container">
      <div class="tag_item">
        <el-image
          class="tag_icon"
          :src="require('@as/serve/knowledge/icon_1.png')"
        ></el-image>
        <div class="tag_title">精细化设计内容</div>
        <div class="tag_subtitle">体系化课程，个性化培训，量身打造</div>
      </div>
      <div class="tag_item">
        <el-image
          class="tag_icon"
          :src="require('@as/serve/knowledge/icon_2.png')"
        ></el-image>
        <div class="tag_title">为好内容付费</div>
        <div class="tag_subtitle">内容精准、价值饱满、解决痛点</div>
      </div>
      <div class="tag_item">
        <el-image
          class="tag_icon"
          :src="require('@as/serve/knowledge/icon_3.png')"
        ></el-image>
        <div class="tag_title">多样大咖专栏</div>
        <div class="tag_subtitle">学习之余，认识大咖、结交同好</div>
      </div>
    </div>
    <div class="main">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="1">全部</el-menu-item>
        <el-menu-item index="2">专业知识</el-menu-item>
        <el-menu-item index="3">职场技能</el-menu-item>
        <el-menu-item index="4">品牌认知</el-menu-item>
      </el-menu>
      <div class="card_list">
        <div
          class="card"
          v-for="item in 17"
          v-bind:key="item"
          @click="toDetail()"
        >
          <div class="card_info">
            <div class="card_title">中国法庭艺术</div>
            <div class="card_subtitle">
              主讲：国浩律师事务所 杨征宇 已更新完成
            </div>
            <div class="card_detail">
              巴比特创始人/CEO，知名科幻作家，区块链理论研究者，区块链“不可能三角”理念提出者…
            </div>
            <div class="card_button">299元/6个月 立即学习</div>
          </div>
          <el-image
            class="card_img"
            :src="require('@as/serve/knowledge/card_img.png')"
          ></el-image>
        </div>
      </div>
      <el-pagination background layout="prev, pager, next" :total="1000">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      activeIndex: '1',
    }
  },
  methods: {
    toDetail() {
      this.$router.push('knowDetail')
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
      this.activeIndex = key
    },
  },
}
</script>

<style lang="less" scoped>
.knowledge {
  background: #fff;
}
.banner {
  height: 400px;
}

& /deep/ .el-pagination {
  margin: 55px 0 0;

  font-size: 14px;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: #a38f5d;
  text-align: center;
}
.tag_container {
  margin: 37px auto 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1150px;
  .tag_item {
    text-align: center;
    .tag_icon {
      width: 120px;
      height: 175px;
    }
    .tag_title {
      margin-top: 30px;
      font-size: 20px;
      color: #393939;
    }
    .tag_subtitle {
      margin-top: 12px;
      font-size: 16px;
      color: #393939;
    }
  }
}
.main {
  margin: 0 auto;
  padding-bottom: 60px;
  width: 1200px;
  & /deep/ .el-menu-item {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
  }
  & /deep/ .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    color: #1c9ab1;
  }
  &　/deep/ .el-menu--horizontal > .el-menu-item.is-active {
    color: #1c9ab1;
    border-color: #1c9ab1;
  }
  .card_list {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    .card {
      margin-bottom: 36px;
      display: flex;
      justify-content: space-between;
      width: 585px;
      box-sizing: border-box;
      border: 1px solid #dedede;
      &:hover {
        box-shadow: 0px 2px 5px 1px rgba(23, 23, 23, 0.13);
      }
      &:not(:nth-child(2n)) {
        margin-right: 28px;
      }

      .card_info {
        padding: 36px 27px 19px;
        display: flex;
        flex-direction: column;
        text-align: left;
        .card_title {
          width: 269px;
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #393939;
        }
        .card_subtitle {
          margin: 30px 0;
          width: 204px;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #393939;
          line-height: 20px;
        }
        .card_detail {
          width: 238px;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #999999;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .card_button {
          margin-top: auto;
          width: 173px;
          height: 28px;
          line-height: 28px;
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #006cb5;
          text-align: center;
          background: #d1f0ff;
          border: 1px solid #d6d3d3;
          border-radius: 8px;
          cursor: pointer;
        }
      }
      .card_img {
        width: 260px;
        height: 309px;
      }
    }
  }
}

/* & /deep/ .el-pagination.is-background .btn-prev:disabled {
  color: #999;
  background: #d6d3d3;
}
& /deep/ .el-pagination.is-background .btn-prev {
  background: #d1f0ff;
}
& /deep/ .el-pagination.is-background .btn-next:disabled {
  color: #606266;
}
& /deep/ .el-pagination.is-background .btn-next {
  background: #d1f0ff;
}
& /deep/ .el-pagination.is-background .el-pager li {
  color: #006cb5;
  border: 1px solid #d6d3d3;
  border-radius: 4px;
  background: transparent;
}
& /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #006cb5;
  background: #d1f0ff;
} */
</style>